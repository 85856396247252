import {
  MaterialsMapping,
  ProgramsMapping,
  ProgramStudent,
  RegistrationMaterial,
  studentGroupInfo,
  StudentProfileInfo,
  CurriculumMaterial,
  RegistrationWrapper,
} from '@interfaces/Student'
import {
  DATE_FORMAT,
  ELECTIVE,
  FINISHED_PROGRAM_TYPES,
  FLEX_LEVEL_STATUS,
  FLEX_VERSION,
  MY_BERLITZ_ENV,
  NOLPID,
  ON_DEMAND,
  PROGRAM_TYPES,
  REG_STATUS,
  VALID_PROGRAM_TYPES,
} from '@utils/constants'
import { IsFlex, getStoredMaterials } from '@utils/helpers/material'
import { getPermissionFromStorage } from '@utils/helpers/permissions'
import { flattenDeep, groupBy, last, values, orderBy, sortBy, uniqBy } from 'lodash'
import moment from 'moment'
import { getCMID } from '@components/RoleBasedView/features/cr8'
import { CURRICULUM_TEST_STATUS } from '@utils/constants'
import PortalProgram from './Program'
import PortalPrograms from './Programs'
import OnlinePlacementTest from './OnlinePlacemenTest'
import { extractNumbeFromStr } from '@utils/helpers'

class PortalStudentProfile {
  student: StudentProfileInfo
  Materials: MaterialsMapping
  Programs: ProgramsMapping
  ElectivesFlag?: boolean
  EventsBoDFlag?: boolean
  MyBerlitzTutorTabFlag?: boolean
  AtRiskProgram?: PortalProgram
  MaterialsForDD?: MaterialsMapping
  CT?: CurriculumMaterial[]
  hasMaterials?: boolean
  OPT?: OnlinePlacementTest

  constructor(student: StudentProfileInfo, activeMaterial?: string) {
    this.student = student

    this.ElectivesFlag = getPermissionFromStorage('flag::GERMAN5-8-feature')
    this.EventsBoDFlag = getPermissionFromStorage('flag::Events-BoD')
    // industry specific content flag
    this.MyBerlitzTutorTabFlag = getPermissionFromStorage('flag::industrySpecificContent')

    this.MaterialsForDD = this.buildDropdown2()
    this.CT = student?.RegistrationWrappers?.reduce((acc, current) => {
      const regMaterials = current?.RegistrationInfo?.Materials
      const coreMaterial =
        regMaterials.length > 1
          ? regMaterials.find((material) => !material.IsElective && !material.IsTest)
          : regMaterials[0]

      const MaterialsWithCoreLP: CurriculumMaterial[] = regMaterials
        .filter((material) => material.IsTest)
        .map((material) => ({
          ...material,
          CoreLP: coreMaterial?.LPID ?? '',
        }))
      acc = acc.concat(...MaterialsWithCoreLP)
      return acc
    }, [] as CurriculumMaterial[])

    /**
     * There are scenarios that Registration has no materials []
     * We need to check if that registration is an OPT
     * if it is then we need to add a placeholder material
     */
    const registrationWrappers = (student?.RegistrationWrappers ?? []).map((registration) =>
      OnlinePlacementTest.generatePlaceholder(registration)
    )

    this.Materials = registrationWrappers?.reduce((acc, current) => {
      const isFlex = IsFlex(current.RegistrationInfo)

      if (current.RegistrationInfo.AtRisk && !this.AtRiskProgram) {
        this.AtRiskProgram = new PortalProgram(
          current.RegistrationInfo.Programs.filter((program) => !FINISHED_PROGRAM_TYPES.includes(program.Status))[0]
        )
      }

      this.hasMaterials = !!(this.hasMaterials || current?.RegistrationInfo?.Materials?.length)
      //const materials = current?.RegistrationInfo?.Materials?.filter(({ LPID }) => LPID).reduce((mAcc, curr) => {
      const materials = current?.RegistrationInfo?.Materials?.reduce((mAcc, curr) => {
        const res = {}
        const levelRegEx = /[0-9]{2}|[0-9]/ // material language level is currently derived from material name

        const [Registration] = this.getRegStatusByLPID(curr)
        const [ProgramEndDateReg] = this.getProgramEndDateByLPID(curr)
        const RegistrationReferences = student?.RegistrationWrappers.filter(({ RegistrationInfo }) =>
          RegistrationInfo.Materials.some(({ LPID }) => LPID === curr.LPID)
        ).map(({ RegistrationInfo }) => RegistrationInfo)

        res[curr.LPID] = {
          ...curr,
          ProgramEndDate: ProgramEndDateReg?.RegistrationInfo.ProgramEndDate,
          TestStatus: Registration?.RegistrationInfo?.TestStatus,
          partialName: curr.MaterialName?.split(levelRegEx)?.[0],
          languageLevel: current?.RegistrationInfo.LanguageLevel,
          language: current?.RegistrationInfo?.Language,
          ProgramType: current?.RegistrationInfo?.ProgramType ?? '',
          ProgramId: current?.RegistrationInfo?.Programs?.[0]?.ProgramId, // assumes 1 program per registration
          FlexVersion: current?.RegistrationInfo?.FlexVersion,
          FlexLevelStatus: current?.RegistrationInfo?.FlexLevelStatus,
          FlexLastLevel: current?.RegistrationInfo.FlexLastLevel,
          UnlimitedLiveCoachingDate: current.RegistrationInfo.UnlimitedLiveCoachingDate,
          ElectiveRequired: isFlex ? this.ElectivesFlag && current.RegistrationInfo.ElectiveRequired : undefined,
          FoundationMaterialId: isFlex
            ? this.ElectivesFlag && current.RegistrationInfo.Materials.find((mat) => !mat.IsElective)?.LPID
            : undefined,
          ElectiveMaterial: isFlex
            ? this.ElectivesFlag && current.RegistrationInfo.Materials.find((mat) => mat.IsElective)
            : undefined,
          IsCore: !isFlex
            ? this.MaterialsForDD?.[curr.LPID] && this.MaterialsForDD?.[curr.LPID]?.suboptions?.length
            : undefined,
          TestMaterials:
            !isFlex && !curr.IsTest && !curr.IsElective && curr.HasTest
              ? Registration?.RegistrationInfo.Materials.filter(({ IsTest }) => IsTest)
              : undefined,
          FromRegistration: {
            ...Registration?.RegistrationInfo,
          },
          RegistrationReferences,
          MaterialLevel: extractNumbeFromStr(curr.MaterialName) || 0,
        }

        return { ...mAcc, ...res }
      }, {})

      return { ...acc, ...materials }
    }, {})

    this.Programs = (student?.RegistrationWrappers || [])?.reduce((acc, current) => {
      const programs = current?.RegistrationInfo?.Programs?.reduce((pAcc, data) => {
        const res = {}
        res[data.ProgramId] = new PortalProgram(
          {
            ...{ ...data, FromRegistration: current.RegistrationInfo },
            FlexLevelStatus: current?.RegistrationInfo?.FlexLevelStatus,
          },
          this.Timezone
        )
        return { ...pAcc, ...res }
      }, {})

      return { ...acc, ...programs }
    }, {})

    if (student) {
      const registration = this.getProgramEndDateByLPID({ LPID: activeMaterial } as RegistrationMaterial)[0]
      this.OPT = new OnlinePlacementTest(student, registration)
    }
  }

  hasSchedInstructorsTab() {
    return (
      this.RegistrationPortalPrograms.studentProgramsSortedDropdown(() => moment.tz(moment(), this.Timezone)).length > 0
    )
  }

  hasEventsTab() {
    return (this.HasOnDemand && this.EventsBoDFlag) || this.RegistrationPortalPrograms.EventsPrograms.length > 0
  }

  AYCLSequence(currentMaterial: RegistrationMaterial) {
    if (currentMaterial.FlexVersion === FLEX_VERSION.SINGLE) {
      return currentMaterial.MaterialName
    }

    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

    const sequence = Object.keys(this.Materials)
      .filter((key) => {
        return (
          (IsFlex(this.Materials[key]) &&
            this.Materials[key].language === currentMaterial.language &&
            this.Materials[key].FlexVersion === currentMaterial.FlexVersion &&
            !this.Materials[key].IsElective &&
            !this.Materials[key].IsTest) ||
          (!IsFlex(this.Materials[key]) && !this.Materials[key].IsElective && !this.Materials[key].IsTest)
        )
      })
      .map((key) => this.Materials[key].MaterialName)
      .filter((materialName) => !/MyBerlitz.*Tutor/.test(materialName))
      .sort(collator.compare)

    return sequence.length > 1 ? sequence[0] + ' - ' + sequence[sequence.length - 1] : sequence[0]
  }

  simpleFormatMaterialDropdown = (materials: RegistrationMaterial[] = []) =>
    materials.map((material) => ({
      label: material.MaterialName,
      value: material.LPID || `{${NOLPID}-${material.RecordId}}`,
      data: this.Programs?.[material.ProgramId],
      material: this.Materials[material.LPID],
      regStatus: undefined,
      sortValue: 0,
      suboptions: [],
    }))

  formatMaterialDropdown = (materials: RegistrationMaterial[] = []) =>
    materials.map((material) => ({
      label: material.MaterialName,
      value: material.LPID || `{${NOLPID}-${material.RecordId}}`,
      data: this.Programs?.[material.ProgramId],
      material: this.Materials[material.LPID],
      regStatus: material.regStatus,
      sortValue: 0,
      suboptions: this.simpleFormatMaterialDropdown(material.suboptions),
    }))

  sortedMaterials(materialList?: MaterialsMapping) {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
    const grp = groupBy(materialList, 'language')
    // sort language by A-Z
    const sortedMaterial: { [key: string]: RegistrationMaterial[] } = Object.keys(grp)
      .sort(collator.compare)
      .reduce((obj, key) => {
        obj[key] = grp[key]
        return obj
      }, {})

    // sort each language material by partial name (the text before the number);
    // if same partial name, sort by language level
    const materials = values(sortedMaterial).map((mats) =>
      mats.sort(
        (a, b) =>
          collator.compare(a?.partialName || '', b?.partialName || '') ||
          (b?.languageLevel || 0) - (a?.languageLevel || 0)
      )
    )

    return flattenDeep(materials)
  }

  getActiveLPIdFromMaterialDropdown = () =>
    this.MaterialDropDown?.find(
      ({ material }) =>
        material?.ElectiveRequired === ELECTIVE.REQUIRED ||
        material?.ElectiveRequired === ELECTIVE.ASSIGNED ||
        (material?.ElectiveRequired === ELECTIVE.NOT_REQUIRED &&
          material?.FlexLevelStatus === FLEX_LEVEL_STATUS.LEVEL_STARTED &&
          !material?.ElectiveMaterial)
    )?.value

  storeActiveDropdownLPId = (activeDropdownLPId: string) => {
    const materials = getStoredMaterials()
    materials[this?.SFId] = activeDropdownLPId
    localStorage.setItem(`portal-selected-material`, JSON.stringify(materials))
  }

  /**
   * a helper function that will return the core material based on the id parameter.
   * note: this function will only return the LPID
   * if you want to have more options please consider to use LearningPath class
   * make sure to remove the industry specific materials
   * @param id
   * @returns
   */
  getCoreLPId = (id: string) => {
    return this.Materials[id]?.FromRegistration?.Materials?.filter(
      ({ IndustrySpecificContent }) => !IndustrySpecificContent
    )?.[0]?.LPID
  }

  get MaterialDropDown() {
    return this.formatMaterialDropdown(
      this.sortedMaterials(this.Materials).filter(
        ({ IsElective, IsTest }) => !this.ElectivesFlag || (!IsElective && !IsTest)
      )
    ).filter(({ material }) => !this.MyBerlitzTutorTabFlag || !material.IndustrySpecificContent)
  }

  get LPMaterialDropDown() {
    return this.formatMaterialDropdown(this.sortedMaterials(this.MaterialsForDD))
  }

  // lpMaterialDropdown for industry specific content
  get LPMaterialDropDownForISC() {
    const materialArr = Object.entries(this.Materials)
      .map(([, val]) => val)
      .filter(({ IndustrySpecificContent }) => !!IndustrySpecificContent)
    return this.formatMaterialDropdown(materialArr)
  }

  // lpMaterialDropdown for industry specific content
  get LPMaterialsForISC() {
    const materialArr = Object.entries(this.Materials)
      .map(([, val]) => val)
      .filter(({ IndustrySpecificContent }) => !!IndustrySpecificContent)
    return materialArr
  }

  get MaterialsISC() {
    return Object.entries(this.Materials)
      .filter(([, value]) => !!value.IndustrySpecificContent)
      .reduce((p, [k, v]) => {
        p[k] = v
        return p
      }, {}) as IDictionary<RegistrationMaterial>
  }

  // returns non Industry specific materials as a lookup
  get MaterialsNonISC() {
    return (
      Object.entries(this.Materials)
        // remove entries that has IndustrySpecificContent if MyBerlitzTutorTabFlag in ON
        // return eveything is MyBerlitzTutorTabFlag is off
        .filter(([, value]) => !this.MyBerlitzTutorTabFlag || !value.IndustrySpecificContent)
        .reduce((p, [k, v]) => {
          p[k] = v
          return p
        }, {})
    )
  }

  get EncodedDistinctISCNames() {
    return uniqBy(this.LPMaterialsForISC, 'IndustrySpecificContent').map(({ IndustrySpecificContent }) =>
      btoa(IndustrySpecificContent || '')
    )
  }

  get SearchMaterialDropDown() {
    return this.formatMaterialDropdown(this.sortedMaterials(this.Materials))
  }

  get CTMaterials() {
    return this.CT
  }

  get HasElectiveMaterial() {
    return (
      this.ElectivesFlag &&
      this.student.RegistrationWrappers.some(({ RegistrationInfo: { ElectiveRequired = '' } }) =>
        [ELECTIVE.REQUIRED, ELECTIVE.ASSIGNED].includes(ElectiveRequired)
      )
    )
  }

  get PortalPrograms() {
    return new PortalPrograms(this.student?.ProgramStudentWrappers ?? [])
  }

  get RegistrationPortalPrograms() {
    const programs = this.Programs
      ? Object.keys(this.Programs).map((id) => ({
          ProgramStudentInfo: { ...this.Programs[id].program },
        }))
      : []

    return new PortalPrograms(programs as ProgramStudent[])
  }

  get LanguageLearning() {
    return this.RegistrationPortalPrograms.ProgramLanguages
  }

  get Levels() {
    return this.RegistrationPortalPrograms.ProgramLevels
  }

  get ProductTypes() {
    return this.RegistrationPortalPrograms.ProductTypes
  }

  get SFId() {
    return this.student?.RecordId
  }

  get Timezone() {
    return this.student?.PreferredTimezone
  }

  get Groups() {
    const wrappers = (this.student?.ProgramStudentWrappers ?? []) as ProgramStudent[]
    return wrappers
      .map((program) => {
        const studentGrp = program.ProgramStudentInfo.studentGroupInfo || ({} as studentGroupInfo)
        studentGrp.Language = program.ProgramStudentInfo?.Language
        studentGrp.ProgramId = program.ProgramStudentInfo?.ProgramId
        return studentGrp.Id ? studentGrp : null
      })
      .filter(Boolean)
  }

  get HasOnDemand() {
    return Object.keys(this.Materials || {})?.some((i) => ON_DEMAND.includes(this.Materials[i].ProgramType || ''))
  }

  get OnlyOnDemand() {
    const types = Object.keys(this.Materials || {})?.map((i) => this.Materials[i].ProgramType)
    const t = [...new Set(types)]

    if (t.length === 1 && t.includes(ON_DEMAND[0])) {
      return true
    }

    // only on demand
    return false
  }

  get AtRiskPrograms() {
    return this.student?.RegistrationWrappers.filter(({ RegistrationInfo }) => RegistrationInfo.AtRisk).reduce(
      (p: PortalProgram[], { RegistrationInfo }) =>
        p.concat(
          RegistrationInfo.Programs.filter((program) => !FINISHED_PROGRAM_TYPES.includes(program.Status)).map(
            (program) => new PortalProgram(program)
          )
        ),
      []
    )
  }

  get MyBerlitzEnv() {
    return this.student?.MyBerlitzEnvironment ?? MY_BERLITZ_ENV.DEFAULT
  }

  get HasBoD1on1Registration() {
    return this.student?.RegistrationWrappers?.some(({ RegistrationInfo }) => {
      return RegistrationInfo.Programs?.some(({ ProgramType }) => {
        return PROGRAM_TYPES.ON_DEMAND_1.includes(ProgramType)
      })
    })
  }

  getCurriculumMaterials(learningPaths, currentLP = '') {
    const currentMaterial = this.Materials[currentLP]
    // lets filter CT from reg wrapper and only use it when it has a match in LMS API
    const filteredForCT = (this.CT?.filter(({ LPID }) => learningPaths.find(({ lpid }) => lpid === LPID)) || []).filter(
      (item) => (currentMaterial ? currentMaterial.RegistrationId === item.RegistrationId : true)
    )

    // Lets check registration materials if it
    // has data from learning path response
    // this will also fix the order of learning path
    const validatedLP = filteredForCT.reduce((acc, item) => {
      const unit = learningPaths.find(({ lpid }) => lpid === item.LPID)
      if (unit) {
        acc = acc.concat(unit)
      }
      return acc
    }, [] as any)

    const curriculumMaterials: CurriculumMaterial[] = validatedLP.reduce((acc, item) => {
      const unit = filteredForCT.find(({ LPID }) => LPID === item.lpid)
      if (unit) {
        const nUnit: CurriculumMaterial[] = item.details.units
          .map((item: any) => {
            const activity = item.activities.find(({ type }) => type === 'p20')
            return {
              ...unit,
              MaterialName: filteredForCT[0].MaterialName,
              CMID: parseInt(getCMID(activity?.url), 10),
              IsIntro:
                item.fullname.includes('Lesson - Introduction and Welcome') || item.shortname.includes('Unit 00 - CT'),
            }
          })
          .filter((item: CurriculumMaterial) => {
            if (item.IsIntro) {
              const hasIntro = acc.find(({ IsIntro }) => IsIntro)
              return !hasIntro
            }

            return item
          })
        acc = acc.concat(...nUnit)
      }

      return acc
    }, [] as CurriculumMaterial[])

    return curriculumMaterials
  }

  get HasMaterialButNoLPID() {
    return Object.keys(this.Materials || {}).length === 0 && this.hasMaterials
  }

  /**
   * Lets arrange registrations by material
   * so that we have the ability to get test unlocked later on
   */
  private getRegistrationsByCoreMaterialId() {
    let registrationsByCoreMaterialId = new Map<string, RegistrationWrapper[]>()
    /**
     * There are scenarios that Registration has no materials []
     * We need to check if that registration is an OPT
     * if it is then we need to add a placeholder material
     */
    const registrationWrappers = (this.student?.RegistrationWrappers ?? []).map((registration) =>
      OnlinePlacementTest.generatePlaceholder(registration)
    )

    registrationsByCoreMaterialId = registrationWrappers?.reduce((acc, current) => {
      const regMaterials = current?.RegistrationInfo?.Materials

      if (regMaterials.length > 1) {
        regMaterials.forEach((material) => {
          if (material) {
            if (!acc.has(material.LPID)) {
              acc.set(material.LPID, [])
            }
            acc.get(material.LPID)?.push(current)
          }
        })
      } else if (regMaterials.length) {
        let material: RegistrationMaterial | undefined = regMaterials[0]
        if (material) {
          if (!acc.has(material.LPID)) {
            acc.set(material.LPID, [])
          }
          acc.get(material.LPID)?.push(current)
        }
      }

      return acc
    }, registrationsByCoreMaterialId)
    return registrationsByCoreMaterialId
  }

  /**
   * This function will help you to get the proper test status.
   */
  private getRegStatusByLPID(currentMaterial: RegistrationMaterial): [RegistrationWrapper | undefined] {
    let currentItem: RegistrationWrapper | undefined

    /**
     * Lets arrange registrations by material
     * so that we have the ability to get test unlocked later on
     */
    let registrationsByCoreMaterialId = this.getRegistrationsByCoreMaterialId()

    if (registrationsByCoreMaterialId) {
      /**
       * find the registration that matches the current material
       */
      const registrations = registrationsByCoreMaterialId.get(currentMaterial.LPID) ?? []

      /**
       * Lets check if the registrations from this current material
       * has test unlocked
       */
      const testUnlockRegs = registrations.filter(
        (registration) => registration.RegistrationInfo.TestStatus === CURRICULUM_TEST_STATUS.UNLOCKED
      )

      /**
       * If it has test unlocked, then use the last item (recent)otherwise use the whatever last registration we have
       */
      if (testUnlockRegs.length) {
        currentItem = last(testUnlockRegs)
      } else {
        currentItem = last(registrations)
      }
    }
    return [currentItem]
  }

  /**
   * This function will help you to get the proper program end date.
   */
  private getProgramEndDateByLPID(currentMaterial: RegistrationMaterial): [RegistrationWrapper | undefined] {
    let currentItem: RegistrationWrapper | undefined

    /**
     * Lets arrange registrations by material
     * so that we have the ability to get test unlocked later on
     */
    let registrationsByCoreMaterialId = this.getRegistrationsByCoreMaterialId()

    if (registrationsByCoreMaterialId) {
      /**
       * find the registration that matches the current material
       */
      const registrations = registrationsByCoreMaterialId.get(currentMaterial.LPID) ?? []

      /**
       * Lets get the latest registration's ProgramEndDate from this current material
       */
      currentItem = orderBy(registrations, ['RegistrationInfo.ProgramEndDate'], ['desc'])[0]
    }
    return [currentItem]
  }

  /**
   * Build material dropdown per registration
   * @returns MaterialsMapping | undefined
   */
  // @ts-ignore
  private buildDropdown2() {
    let materialsForDD: MaterialsMapping | undefined

    /**
     * There are scenarios that Registration has no materials []
     * We need to check if that registration is an OPT
     * if it is then we need to add a placeholder material
     */
    const registrationWrappers = (this.student?.RegistrationWrappers ?? []).map((registration) =>
      OnlinePlacementTest.generatePlaceholder(registration)
    )

    /**
     * Lets group registrations by registration id
     * so that we have the ability to get test unlocked later on
     */
    let registrationsByRegistrationId = new Map<string, RegistrationWrapper[]>()
    registrationsByRegistrationId = registrationWrappers?.reduce((acc, current) => {
      if (current?.RegistrationInfo) {
        if (!acc.has(current?.RegistrationInfo?.RegistrationId)) {
          acc.set(current?.RegistrationInfo?.RegistrationId, [])
        }
        acc.get(current?.RegistrationInfo?.RegistrationId)?.push(current)
      }

      return acc
    }, registrationsByRegistrationId)

    let dropDownByCoreMaterialId = new Map<string, RegistrationMaterial>()
    if (registrationsByRegistrationId) {
      materialsForDD = {}

      const levelRegEx = /[0-9]{2}|[0-9]/ // material language level is currently derived from material name
      for (let [registrationId, registrations] of registrationsByRegistrationId) {
        /**
         * Lets check if the registrations from these core material
         * has test unlocked
         */
        const registrationsWithTestUnlocked = registrations.filter(
          (registration) => registration.RegistrationInfo.TestStatus === CURRICULUM_TEST_STATUS.UNLOCKED
        )

        /**
         * If it has test unlocked, then use the last item otherwise use the whatever last registration we have
         */
        const current = registrationsWithTestUnlocked.length ? last(registrationsWithTestUnlocked) : last(registrations)
        // if the industry specific content ff is on, filter the main course lp dd to show non ISC materials
        const regMaterials = (current?.RegistrationInfo?.Materials ?? []).filter(
          ({ IndustrySpecificContent }) => !this.MyBerlitzTutorTabFlag || !IndustrySpecificContent
        )
        const testMaterial = regMaterials.find((material) => material.IsTest)

        let coreMaterial: RegistrationMaterial | undefined =
          regMaterials.length > 1
            ? regMaterials.find((material) => !material.IsElective && !material.IsTest) || regMaterials[0]
            : regMaterials[0]

        /**
         * For Flex and BoD:
         *  - If the assigned value to coreMaterial is either elective or CT then we need to set it to undefined
         * For Live online:
         *  - If the assigned value to coreMaterial is elective or test then we don't need to set it to undefined
         */
        const program = this.getProgramByRegistration(current)
        if (!program.isProgramLiveOnline) {
          if (coreMaterial?.IsElective || coreMaterial?.IsTest) {
            if (!regMaterials[0]?.IsElective || !regMaterials[0]?.IsTest) {
              coreMaterial = undefined
            }
          }
        }

        const dd = {
          ...coreMaterial,
          partialName: coreMaterial?.MaterialName?.split(levelRegEx)?.[0],
          languageLevel: Number((coreMaterial?.MaterialName?.match(levelRegEx) || [])[0] || 0),
          language: current?.RegistrationInfo?.Language,
          ProgramType: current?.RegistrationInfo?.ProgramType ?? '',
          suboptions: [] as any,
          testStatus: current?.RegistrationInfo?.TestStatus,
          regStatus: current?.RegistrationInfo?.Status,
        }

        const nonCTmaterials = regMaterials.filter((material) => !material.IsTest)
        /**
         * Finally lets build the dropdown
         * Lets add first non CT materials (core material, elective)
         */
        dd.suboptions = nonCTmaterials.map((curr) => {
          return {
            ...curr,
            partialName: curr.MaterialName?.split(levelRegEx)?.[0],
            languageLevel: Number((curr?.MaterialName?.match(levelRegEx) || [])[0] || 0),
            language: current?.RegistrationInfo?.Language,
            testStatus: current?.RegistrationInfo.TestStatus,
          }
        })

        /**
         * If there is no non CT materials then we don't display CT materials
         * since CT is tied to either core or elective materials
         */
        if (testMaterial && dd.suboptions.length > 0) {
          const languageLevel = current?.RegistrationInfo?.LanguageLevel || ''
          const language = current?.RegistrationInfo?.Language || ''
          const MaterialName = `Curriculum Test ${language} ${languageLevel}`

          /**
           * Lets add CT materials here
           */
          dd.suboptions?.push({
            ...testMaterial,
            partialName: MaterialName,
            MaterialName,
            languageLevel,
            language,
          })
        }

        dropDownByCoreMaterialId.set(registrationId, dd)
      }
    }

    /**
     * Convert Map to plain Object
     */
    for (const [key, value] of dropDownByCoreMaterialId) {
      materialsForDD![key] = value
    }

    return materialsForDD
  }

  // @ts-ignore
  private buildDropDown() {
    let materialsForDD: MaterialsMapping | undefined
    /**
     * Lets arrange registrations by first item of the material
     * so that we have the ability to get test unlocked later on
     */
    let registrationsByCoreMaterialId = new Map<string, RegistrationWrapper[]>()
    registrationsByCoreMaterialId = this.student?.RegistrationWrappers?.reduce((acc, current) => {
      const regMaterials = current?.RegistrationInfo?.Materials

      /**
       * There are scenarios that Materials is []
       * so lets defined in TS that coreMaterial may get undefined value
       */
      let coreMaterial: RegistrationMaterial | undefined

      if (regMaterials.length > 1) {
        coreMaterial = regMaterials.find((material) => !material.IsElective && !material.IsTest) || regMaterials[0]
      } else if (regMaterials.length) {
        coreMaterial = regMaterials[0]
      }

      if (coreMaterial) {
        if (!acc.has(coreMaterial.LPID)) {
          acc.set(coreMaterial.LPID, [])
        }
        acc.get(coreMaterial.LPID)?.push(current)
      }

      return acc
    }, registrationsByCoreMaterialId)

    /**
     * General idea in here is that we are going to build dropdown list by core material id
     * that would show in these following order:
     * example
     * Curriculum Test English 1 (Core)
     * -- Meetings 1-5 level 1 (Elective)
     * -- Curriculum Test English 1 (CT)
     * The way we are going to build it is that we are going to check first
     * if the registrations contains test unlocked, if has,
     * then we are going to use it otherwise just whatever the last registration is
     */
    let dropDownByCoreMaterialId = new Map<string, RegistrationMaterial>()
    if (registrationsByCoreMaterialId) {
      materialsForDD = {}

      const levelRegEx = /[0-9]{2}|[0-9]/ // material language level is currently derived from material name
      for (let [coreMaterialId, registrations] of registrationsByCoreMaterialId) {
        /**
         * Lets check if the registrations from these core material
         * has test unlocked
         */
        const testUnlockRegs = registrations.filter(
          (registration) => registration.RegistrationInfo.TestStatus === CURRICULUM_TEST_STATUS.UNLOCKED
        )

        /**
         * If it has test unlocked, then use the last item (recent)otherwise use the whatever last registration we have
         */
        let current: RegistrationWrapper | undefined
        if (testUnlockRegs.length) {
          current = last(testUnlockRegs)
        }

        /**
         * If there is no test unlocked material
         */
        //const current = testUnlockRegs.length ? last(testUnlockRegs) : last(registrations)
        const regMaterials = current?.RegistrationInfo?.Materials ?? []
        const testMaterial = regMaterials.find((material) => material.IsTest)

        const coreMaterial =
          regMaterials.length > 1
            ? regMaterials.find((material) => !material.IsElective && !material.IsTest) || regMaterials[0]
            : regMaterials[0]

        const dd = {
          ...coreMaterial,
          partialName: coreMaterial?.MaterialName?.split(levelRegEx)?.[0],
          languageLevel: Number((coreMaterial?.MaterialName?.match(levelRegEx) || [])[0] || 0),
          language: current?.RegistrationInfo?.Language,
          ProgramType: current?.RegistrationInfo?.ProgramType ?? '',
          suboptions: [] as any,
          testStatus: current?.RegistrationInfo?.TestStatus,
        }

        const isFlex = IsFlex(dd)

        const nonCTmaterials = regMaterials.filter((material) => !material.IsTest)
        if (!isFlex) {
          /**
           * Finally lets build the dropdown
           * Lets add first non CT materials (core material, elective)
           */
          dd.suboptions = nonCTmaterials.map((curr) => {
            return {
              ...curr,
              partialName: curr.MaterialName?.split(levelRegEx)?.[0],
              languageLevel: Number((curr?.MaterialName?.match(levelRegEx) || [])[0] || 0),
              language: current?.RegistrationInfo?.Language,
              testStatus: current?.RegistrationInfo.TestStatus,
            }
          })

          if (testMaterial) {
            const languageLevel = current?.RegistrationInfo?.LanguageLevel || ''
            const language = current?.RegistrationInfo?.Language || ''
            const MaterialName = `Curriculum Test ${language} ${languageLevel}`

            /**
             * Lets add CT materials here
             */
            dd.suboptions?.push({
              ...testMaterial,
              partialName: MaterialName,
              MaterialName,
              languageLevel,
              language,
            })
          }
        }

        dropDownByCoreMaterialId.set(coreMaterialId, dd)
      }
    }

    /**
     * Convert Map to plain Object
     */
    for (const [key, value] of dropDownByCoreMaterialId) {
      materialsForDD![key] = value
    }

    return materialsForDD
  }

  getProgramByMaterialId = (id?: string) => {
    const programId = this?.Materials[id ?? '']?.ProgramId
    return this?.Programs[programId]
  }

  private getProgramByRegistration(reg: RegistrationWrapper | undefined) {
    const registration = this.student?.RegistrationWrappers?.find(
      (registration) => registration?.RegistrationInfo?.RegistrationId === reg?.RegistrationInfo?.RegistrationId
    )

    const programsByStudent = (registration?.RegistrationInfo?.Programs ?? []).map((program) => {
      const p: ProgramStudent = {
        ProgramStudentInfo: {
          ...program,
        },
      }
      return p
    })
    const programs = new PortalPrograms(programsByStudent)
    return programs.getProgram(reg?.RegistrationInfo?.Programs?.[0]?.ProgramId || '')
  }

  eventsSortedDropdown = (getDateWithUserTimezone?: any, nonFlex: boolean = false) => {
    const sorted = sortBy(
      this.student.RegistrationWrappers,
      (reg) => !VALID_PROGRAM_TYPES.map((type) => type.toLowerCase() === reg.RegistrationInfo.Status.toLowerCase()),
      (reg) =>
        moment(
          reg.RegistrationInfo?.Programs?.[0]?.ContractEndDate || reg.RegistrationInfo.ProgramEndDate,
          DATE_FORMAT.BASIC
        ).diff(getDateWithUserTimezone(), 'days') < 0
    )
      .filter(
        ({ RegistrationInfo }) => !RegistrationInfo?.Programs?.some(({ PortalNotNecessary }) => PortalNotNecessary)
      )
      .filter(({ RegistrationInfo }) => {
        // filter for https://berlitz.atlassian.net/browse/SCH-690
        let defaultFilter =
          RegistrationInfo.Status !== REG_STATUS.CANCELLED &&
          RegistrationInfo.Status !== REG_STATUS.COMPLETED &&
          RegistrationInfo.Status !== REG_STATUS.DRAFT &&
          !(
            getDateWithUserTimezone &&
            RegistrationInfo.Status === REG_STATUS.ACTIVE &&
            moment(
              RegistrationInfo?.Programs?.[0]?.ContractEndDate || RegistrationInfo.ProgramEndDate,
              DATE_FORMAT.BASIC
            ).diff(getDateWithUserTimezone(), 'days') < 0
          )

        // https://berlitz.atlassian.net/browse/SCH-817
        if (nonFlex && RegistrationInfo.Programs?.length) {
          return !new PortalProgram(RegistrationInfo?.Programs?.[0]).isFlexProgram() && defaultFilter
        }

        return defaultFilter
      })
      .filter(
        // filter for https://berlitz.atlassian.net/browse/SCH-740
        ({ RegistrationInfo }) =>
          !(
            [REG_STATUS.ACTIVE, REG_STATUS.PENDING_START].includes(RegistrationInfo.Status || '') &&
            RegistrationInfo?.Programs?.some(({ PortalNotNecessary }) => PortalNotNecessary)
          )
      )
      .filter(
        // filter for https://berlitz.atlassian.net/browse/PS-169
        ({ RegistrationInfo }) =>
          getDateWithUserTimezone()?.isBefore(
            RegistrationInfo?.Programs?.[0]?.ContractEndDate || RegistrationInfo.ProgramEndDate,
            'day'
          )
      )

    return sorted.map((reg) => {
      const program = reg.RegistrationInfo?.Programs?.[0]
      const {
        Language = reg.RegistrationInfo.Language,
        DeliveryMode,
        ProgramName = reg.RegistrationInfo.DisplayName,
        ProgramEnrollDate,
        Status = reg.RegistrationInfo.Status,
        ContractEndDate = reg.RegistrationInfo.ProgramEndDate,
        ProgramId,
      } = program || {}
      const DeliveryModeText = DeliveryMode ? `${DeliveryMode} - ` : ''

      const expired =
        getDateWithUserTimezone &&
        reg.RegistrationInfo.Status === REG_STATUS.ACTIVE &&
        moment(ContractEndDate, DATE_FORMAT.BASIC).diff(getDateWithUserTimezone(), 'days') < 0
          ? ' (Expired)'
          : ''

      return {
        label: `${Language} - ${DeliveryModeText}${ProgramName} - ${moment(ProgramEnrollDate).format(
          'MMMM DD, YYYY'
        )} - ${Status}${expired}`,
        value: ProgramId || reg.RegistrationInfo?.RegistrationId,
        data: program || reg.RegistrationInfo,
      }
    })
  }

  getRegistrationByProgramId = (programId?: string) => {
    return this.student?.RegistrationWrappers.find(
      (registration) => registration?.RegistrationInfo?.Programs?.[0]?.ProgramId === programId
    )
  }

  get hasActiveIndSpecificContent() {
    return Object.entries(this.Materials).some(
      ([, { IndustrySpecificContent, RegistrationReferences }]) =>
        !!IndustrySpecificContent &&
        RegistrationReferences.some(({ Status }) => [REG_STATUS.ACTIVE, REG_STATUS.PENDING_START].includes(Status))
    )
  }
}

export default PortalStudentProfile
