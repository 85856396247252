import { radiusXxxl } from '@utils/constants'
import { LP_STATUS, LP_UNIT_TYPE } from '@utils/constants/learningPath'
import styled, { css } from 'styled-components'
import { ActiveProp, DetailsProp, StatusProp, TabProp } from './interface'

const DOWNLOAD_BTN_HEIGHT = '48px'

export const Container = styled.div`
  position: relative;

  a:visited {
    color: ${({ theme }) => theme.colors.brandPrimary};
  }
`

export const TileContainer = styled.div<StatusProp & ActiveProp>`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.ui04};
  border-top: 12px solid ${({ theme }) => theme.colors.ui04};
  border-radius: ${({ theme }) => theme.radius.lg};
  background-color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  color: ${({ theme }) => theme.colors.brandSecondaryContrast};

  span[data-key='icon-Tick'],
  span[data-key='icon-TimeClock'],
  span[data-key='icon-Minus'],
  span[data-key='icon-IconSignBadgeCircleAlternate'],
  span[data-key='icon-IconPin'],
  span[data-key='icon-IconAttachment'] {
    display: inline-block;
    background-color: ${({ theme }) => theme.colors.ui04};
    color: ${({ theme }) => theme.colors.brandSecondaryContrast};
    padding: ${({ theme }) => theme.space.xxs};
    border-radius: ${({ theme }) => `0 0 ${theme.radius.md} ${theme.radius.md}`};
    width: 35px;
    text-align: center;
  }

  span[data-key='icon-IconSignBadgeCircleAlternate'] {
    color: ${({ theme }) => theme.palette.info100};
  }

  span[data-key='icon-Minus'] {
    color: ${({ theme }) => theme.palette.blueGray50};
  }

  span[data-key='icon-ControlsPlay'] {
    background-color: ${({ theme }) => theme.colors.brandSecondaryContrast};
    color: ${({ theme }) => theme.colors.brandPrimaryContrast};
    padding: ${({ theme }) => theme.space.xxs};
    border-radius: ${({ theme }) => theme.radius.md};
    font-size: ${({ theme }) => theme.fontSize.xxxs};
    width: 24px;
    text-align: center;
    display: inline-block;
  }

  button[data-key='max-button'] {
    background-color: ${({ theme }) => theme.colors.ui04};
    color: ${({ theme }) => theme.colors.brandSecondaryContrast};
    outline: none;
  }

  .position-relative {
    position: relative;
  }

  ${({ theme, status }) =>
    status === LP_STATUS.COMPLETE &&
    css`
      background: ${theme.palette.green20};
      border: 1px solid ${theme.palette.green100};
      border-top: 12px solid ${theme.palette.green100};

      span[data-key='icon-Tick'],
      span[data-key='icon-BerlitzCenter'] {
        background-color: ${theme.palette.green100};
      }

      button[data-key='max-button'] {
        background-color: ${theme.palette.green100};
      }
    `}

  ${({ theme, status }) =>
    status === LP_STATUS.IN_PROGRESS &&
    css`
      background: ${theme.palette.blue10};
      border: 1px solid ${theme.colors.brandPrimary};
      border-top: 12px solid ${theme.colors.brandPrimary};

      span[data-key='icon-Tick'],
      span[data-key='icon-IconPin'],
      span[data-key='icon-TimeClock'] {
        background-color: ${theme.colors.brandPrimary};
        color: ${theme.colors.brandPrimaryContrast};
      }

      button[data-key='max-button'] {
        background-color: ${theme.colors.brandPrimary};
        color: ${theme.colors.brandPrimaryContrast};
      }
    `}

    ${({ theme, status }) =>
    status === LP_UNIT_TYPE.REVIEW &&
    css`
      background: ${theme.palette.info20};
      border: 1px solid ${theme.palette.info100};
      border-top: 12px solid ${theme.palette.info100};

      span[data-key='icon-Tick'],
      span[data-key='icon-TimeClock'] {
        background-color: ${theme.palette.info100};
      }

      button[data-key='max-button'] {
        background-color: ${theme.palette.info100};
      }
    `}

    ${({ theme, status }) =>
    status === LP_STATUS.COURSE_META &&
    css`
      border: 1px solid ${theme.palette.warning100};
      border-top: 12px solid ${theme.palette.warning100};

      span[data-key='icon-IconAttachment'] {
        background-color: ${theme.palette.warning100};
        color: ${theme.colors.brandPrimaryContrast};
      }

      button[data-key='max-button'] {
        background-color: ${theme.palette.warning100};
      }
    `}

    ${({ active }) =>
    active &&
    css`
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    `}
`

export const Content = styled.div<DetailsProp & TabProp>`
  width: 229px;
  height: 345px;
  padding-left: ${({ theme }) => theme.space.sm};
  padding-right: ${({ theme }) => theme.space.sm};

  button[data-key='max-button'] {
    outline: none;
    padding: ${({ theme }) => `3px ${theme.space.lg}`};
    background-color: ${({ theme }) => theme.colors.brandPrimaryContrast};
    color: ${({ theme }) => theme.colors.brandPrimaryInteract};
  }

  ${({ showDetails }) =>
    showDetails &&
    css`
      height: 170px;
      padding-left: 0;
      padding-right: 0;
    `}
`
export const Icon = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.space.sm};
`

export const VideoIcon = styled.div`
  position: absolute;
  right: 0;
  top: ${({ theme }) => theme.space.xxs};
  cursor: pointer;
`

export const Text = styled.div<StatusProp>`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: ${({ theme }) => theme.lineHeight.md};
  height: 94px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.63px;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${({ theme, status }) =>
    status === LP_STATUS.COURSE_META &&
    css`
      height: initial;
      margin-bottom: ${theme.space.sm};
    `}
`

export const ConfirmText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: ${({ theme }) => theme.lineHeight.md};
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.63px;
`

export const Link = styled.div<StatusProp & { is3rdParty?: boolean }>`
  background: ${({ theme }) => theme.colors.brandPrimaryContrast};
  border-radius: ${({ theme }) => theme.radius.md};
  border: 1px solid ${({ theme }) => theme.colors.ui04};
  margin-bottom: ${({ theme }) => theme.space.sm};
  height: 103px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 5px;
  overflow-y: auto;

  a {
    font-size: ${({ theme }) => theme.fontSize.xxs};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin-left: ${({ theme }) => theme.space.xs};
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  ${({ status }) =>
    status !== LP_STATUS.NOT_YET_STARTED &&
    css`
      border: 0;
    `}

  ${({ theme, status, is3rdParty }) =>
    status === LP_STATUS.COURSE_META &&
    css`
      padding-top: ${theme.space.sm};
      padding-bottom: ${theme.space.lg};
      padding-left: ${theme.space.xxs};
      padding-right ${theme.space.xxs};
      font-size: ${theme.fontSize.xxxs};
      line-height: ${theme.lineHeight.md};
      border: 1px solid ${theme.palette.gray100};
      height: ${is3rdParty ? '225px' : theme.layout.xxl};
      display: block;
    `}
`

export const StyledIcon = styled.span<{ status?: string }>`
  ${({ theme, status }) => css`
    span:first-child {
      width: ${theme.layout.sm};
      height: ${theme.layout.sm};
      font-size: ${theme.fontSize.xs};
      border-radius: ${radiusXxxl};
      background-color: ${status === LP_STATUS.IN_PROGRESS
        ? theme.palette.purple100
        : status === LP_STATUS.COMPLETE
          ? theme.palette.green100
          : theme.colors.ui07};
      display: inline-block;
      vertical-align: middle;
      margin-left: ${theme.space.xs};
      padding: 0;

      ${status === LP_STATUS.IN_PROGRESS && `color: ${theme.palette.white};`}
      ${status === LP_STATUS.COMPLETE && `color: ${theme.palette.charcoal};`}

      svg {
        display: block;
        margin: auto;
        margin-top: ${theme.space.xs};
      }
    }
  `}
`

export const LinkItem = styled.div`
  margin-bottom: ${({ theme }) => theme.space.xs};

  .display-inline-block {
    display: inline-block;
  }

  .display-flex {
    display: flex;
  }

  span[data-key='icon-RefreshArrow'] {
    position: relative;
    font-size: 16px;
  }

  span[data-key='icon-TrophyStar'] {
    background-color: ${({ theme }) => theme.palette.warning100};
    color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  }
`

export const LinkItemFlex = styled(LinkItem as any)`
  display: flex;
  align-items: center;
`

export const ProgressContainer = styled.div`
  margin-left: 45px;
  width: 150px;
  margin-right: ${({ theme }) => theme.space.xs};
`

export const Bridge = styled.div<StatusProp>`
  width: 12%;
  position: absolute;
  top: 140px;
  left: 230px;
  z-index: 1;
  border: 4px dashed ${({ theme }) => theme.palette.blueGray50};

  ${({ theme, status }) =>
    status === LP_STATUS.COMPLETE &&
    css`
      border: 4px solid ${theme.colors.brandSecondary};
    `}
`

export const ContentLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  color: ${({ theme }) => theme.colors.brandPrimary};
`

export const ContentList = styled.ul`
  margin-top: ${({ theme }) => theme.space.sm};
  font-size: ${({ theme }) => theme.fontSize.xxs};

  li::before {
    color: ${({ theme }) => theme.colors.brandPrimary};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    content: '';
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  li {
    margin-bottom: ${({ theme }) => theme.space.xxs};
    margin-left: ${({ theme }) => theme.space.md};
    white-space: pre-line;
  }
`

export const StyledScroller = styled.div`
  height: 167px;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: ${({ theme }) => theme.space.xs};
  padding-left: ${({ theme }) => theme.space.sm};
  padding-right: ${({ theme }) => theme.space.sm};
`

export const DownloadSelection = styled.div<StatusProp>`
  position: absolute;
  z-index: 999;
  width: 89%;
  background-color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  margin-top: ${({ theme }) => theme.space.xxxs};
  border: 1px solid ${({ theme }) => theme.palette.gray100};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  line-height: ${({ theme }) => theme.lineHeight.md};
  bottom: ${DOWNLOAD_BTN_HEIGHT};

  ul {
    overflow: hidden;
    height: 130px;
    overflow-y: scroll;

    li {
      padding-left: ${({ theme }) => theme.space.xs};
      padding-top: ${({ theme }) => theme.space.xxs};
      padding-bottom: ${({ theme }) => theme.space.xxs};
      padding-right: ${({ theme }) => theme.space.xs};
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.colors.ui06};
        color: ${({ theme }) => theme.colors.brandPrimary};
      }
    }

    ${({ status }) =>
      status === LP_STATUS.COURSE_META &&
      css`
        height: 80px;
      `}
  }
}
`

export const RedoButton = styled.span`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.space.xxs};
`

export const Confirmation = styled.div`
  position: absolute;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  border-radius: ${({ theme }) => theme.radius.lg};

  .display-flex {
    display: flex;
  }

  .text-container {
    padding-left: ${({ theme }) => theme.space.xs};
    padding-right: ${({ theme }) => theme.space.xs};
    margin-top: ${({ theme }) => theme.layout.lg};
  }

  .subtext {
    font-size: ${({ theme }) => theme.fontSize.xxs};
    line-height: ${({ theme }) => theme.lineHeight.md};
    margin-top: ${({ theme }) => theme.space.sm};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.49px;
    text-align: center;
  }

  .btn-container {
    margin-top: ${({ theme }) => theme.space.sm};
  }

  .checkbox-container {
    margin-top: ${({ theme }) => theme.layout.lg};
    text-align: center;
  }
`

export const CloseButton = styled.span`
  margin-left: auto;
  cursor: pointer;
`

export const EmptyDownloadContainer = styled.div<StatusProp>`
  position: relative;
  height: ${DOWNLOAD_BTN_HEIGHT};
`

export const DownloadContainer = styled.div<StatusProp>`
  position: relative;
  padding-left: ${({ theme }) => theme.space.sm};
  padding-right: ${({ theme }) => theme.space.sm};
  height: ${DOWNLOAD_BTN_HEIGHT};

  ${({ status }) =>
    status === LP_STATUS.COURSE_META &&
    css`
      padding-left: 0;
      padding-right: 0;
      position: absolute;
      width: 90%;
      bottom: 0;

      ${DownloadSelection} {
        width: 100%;
      }
    `}

  button[data-key='max-button'] {
    outline: none;
    padding: ${({ theme }) => `3px ${theme.space.lg}`};
    background-color: ${({ theme }) => theme.colors.brandPrimaryContrast};
    color: ${({ theme }) => theme.colors.brandPrimaryInteract};
  }
`

export const Anchor = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-left: ${({ theme }) => theme.space.xs};
  color: ${({ theme }) => theme.colors.brandPrimary};
  display: inline;
  text-decoration: underline;
  cursor: pointer;
`

export const StatusText = styled.div`
  ${({ theme }) => `
    margin-left: ${theme.space.xs};
    font-size: ${theme.fontSize.xxxs};
  `}

  line-height: 1.33;
  letter-spacing: 0.42px;
`

export const OverrideAITutorLink = styled.div`
  button[data-key='max-button'] {
    color: ${({ theme }) => theme.colors.text00};
    background-color: ${({ theme }) => theme.colors.brandSecondary} !important;
    padding: ${({ theme }) => `${theme.space.xs} ${theme.space.lg}`} !important;
  }
`
