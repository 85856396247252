import { Consumer } from '@layouts/PageLayout/Context'
import Router from 'next/router'
import * as React from 'react'

import { RouteLinkProps } from './interface'
import { StyledLink } from './style'

const RouteLink: React.FC<RouteLinkProps> = (props) => (
  <Consumer>
    {({ isFormDirty, displayUnsavedModal }) => (
      <StyledLink
        key={props.path}
        onClick={() => {
          if (props.path == '/learning-path' && props.studentSFiD) {
            localStorage.removeItem(`lp:${props.studentSFiD}:activeHash`)
          }
          isFormDirty
            ? displayUnsavedModal(props.path, props.dynamicPath)
            : Router[props.replace ? 'replace' : 'push'](props.dynamicPath || props.path, props.path)
        }}
        withHoverEffect={props.withHoverEffect}
        {...props}
      >
        {props.children}
      </StyledLink>
    )}
  </Consumer>
)

export default RouteLink
